import { useEffect, useState } from 'react';
import { Discography } from './components/Discography';
import { Player } from './components/Player';
import { PlayerProvider } from './components/PlayerProvider';
import { ALBUMS, OTHER, tokenize } from './albums';

export const App = () => {
  const [album, setAlbum] = useState(() => {
    const albumName = window.location.pathname.split('/').pop();

    if (!albumName || albumName === 'music') {
      return null;
    }

    return (
      ALBUMS.find((album) => tokenize(album.name, '-') === albumName) ||
      OTHER.find((album) => tokenize(album.name, '-') === albumName)
    );
  });

  const updateAlbumAndUpdateURL = (album) => {
    setAlbum(album);
    window.history.pushState(
      null,
      '',
      album ? `/music/${tokenize(album.name, '-')}` : '/music'
    );
  };

  useEffect(() => {
    window.onpopstate = () => {
      const albumName = window.location.pathname.split('/').pop();

      if (!albumName || albumName === 'music') {
        setAlbum(null);
        return;
      }

      setAlbum(
        ALBUMS.find((album) => tokenize(album.name, '-') === albumName) ||
          OTHER.find((album) => tokenize(album.name, '-') === albumName)
      );
    };
  }, []);

  return (
    <PlayerProvider>
      <Discography album={album} setAlbum={updateAlbumAndUpdateURL} />
      <Player album={album} setAlbum={updateAlbumAndUpdateURL} />
    </PlayerProvider>
  );
};
